@keyframes "App-logo-spin" {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #bdbcbc;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  font-size: 0.9rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiFormControl-root {
  margin-bottom: 10px;
}

.MuiAutocomplete-input {
  min-width: none !important;
}

.ant-upload {
  font-family: "Montserrat", serif;
}

.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.ant-picker-dropdown {
  z-index: 10000;
}

.scada-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding-top: 15px;
  padding-bottom: 15px;
  margin: 15px;
}

.scada-chart {
  background-color: transparent;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
}

.scada-card {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 12px;
  width: 100% !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.scada-card-title {
  font-weight: bold !important;
  font-size: 14px !important;
}

.scada-card-button {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 12px !important;
}

.scada-card-image {
  margin-top: 5px;
  margin-left: 5px;
  width: 35px !important;
}

.scada-card-zoom {
  width: 100%;
  margin: 20px 0px 20px 5px;
}

.reset-zoom {
  border: 0;
  line-height: 1.75;
  text-transform: uppercase;
  position: fixed;
  top: 15%;
  right: 0;
  margin-right: 70px;
  padding: 3px 10px;
  border-radius: 4px;
  border: 1px solid rgba(53, 186, 7, 0.9);
  color: #111;
  background-color: white;
  cursor: pointer;
  z-index: 1000;
}

.control-plant-list {
  margin-bottom: -10px;
}

.custom-overlay-card-title {
  font-weight: bold !important;
  font-size: 14px !important;
  text-align: center;
}

.alarm-lvl-0 {
  color: rgb(35, 106, 182);
}

.alarm-lvl-10 {
  color: rgba(53, 186, 7, 0.9);
}

.alarm-lvl-20 {
  color: rgb(246, 81, 29);
}

.alarm-lvl-30 {
  color: rgb(215, 38, 56);
}

.neutral-button {
  background-color: #808080 !important;

  &:hover {
    background-color: #6e6e6e !important;
  }
}

.map-tooltip-item {
  display: flex;
  flex-direction: column !important;
}

.mapboxgl-popup-content {
  background: transparent !important;
  padding: 0px !important;
}

.page-wrapper {
  min-height: calc(100vh - 12vh);
  border-radius: 12px;
  width: 100%;
  padding: 20px;
  margin-top: 68px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.menu-divider {
  width: 92%;
  margin-left: 15px !important;
  margin-bottom: 15px !important;
  margin-top: -5px !important;
}

.custom-card {
  width: 100% !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.MuiDialog-paper {
  border-radius: 12px !important;
  box-shadow: none !important;
}

.MuiSelect-select {
  height: 1.4375em !important;
}

.MuiAppBar-root {
  background-color: transparent !important;
}

.MuiPopover-paper {
  border-radius: 10px !important;
  box-shadow:
    rgba(31, 32, 32, 0.2) 0px 5px 5px -3px,
    rgba(0, 0, 0, 0.14) 0px 8px 10px 1px,
    rgba(56, 56, 56, 0.12) 0px 3px 14px 2px !important;
}

.search-card {
  width: 100%;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin-bottom: 20px;
  padding: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
}

.navbar {
  margin-top: 5px;
  overflow: hidden;
}

.navbar-list {
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: #35ba07 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: #35ba07 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(53, 186, 7, 0.9) !important;
}

.ant-upload-list-text {
  max-width: 150px;
}

.ant-btn-default {
  color: #35ba07 !important;
  border-color: rgba(53, 186, 7, 0.9) !important;
}

.ant-btn-default:hover {
  color: #35ba07 !important;
  background-color: rgba(53, 186, 7, 0.05) !important;
}

.ant-btn-primary {
  background-color: #35ba07 !important;
  border-color: rgba(53, 186, 7, 0.9) !important;
}

.ant-picker-focused {
  border: 2px solid #35ba07 !important;
  border-inline-end-width: 1px;
}

.ant-picker-active-bar {
  background: #35ba07 !important;
}

.ant-picker-cell::after {
  border: none !important;
}

.ant-picker:hover {
  border-color: black;
}

@media (max-height: 1000px) {
  .navbar-list {
    padding-right: 5px;
  }
}

@media screen and (max-width: 780px) {
  .page-wrapper {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .MuiDrawer-paper {
    border-radius: 0px 12px 12px 0px;
  }
}

@media (max-width: 576px) {
  .ant-picker-panels {
    flex-direction: column !important;
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
    max-width: 100%;
    min-height: 120px;
  }
  .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout {
    display: block;
  }
}

.mapboxgl-canvas-container {
  cursor: pointer !important;
}

.scada-filters {
  .custom-autocomplete {
    .MuiFormControl-root {
      /* Retire la marge inférieure prévue par MUI */
      margin-bottom: 0px !important;
    }
  }
}
